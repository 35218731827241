import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {clinicService as service} from "../../../services"

const initElement = {
     email:"",
     nif:"",
     name: "",
     nameBill: "",
     picture: null,
     street: "",
     phone:"",
     number: "",
     door:"",
     floor:"",
     stairs:"",
     city: "",
     country: "",
     postalcode: "",
     textTicket: "",
     serieBill: "",
     numBill: "",
     active: true

  };


export default function FormClinic() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);


  const estructureform =  [
          {
          type:"input",
          name: "nif",
          label: "Nif",
          col: 4
          },
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"input",
           name: "nameBill",
           label: "Nombre en factura",
           col: 4
          },
          {
           type:"input",
           name: "email",
           label: "Email",
           col: 4
          },
          {
           type:"input",
           name: "phone",
           label: "Teléfono",
           col: 4
          },
          {
           type:"input",
           name: "street",
           label: "Dirección",
           col: 4
          },
          {
           type:"input",
           name: "number",
           label: "Nº",
           col: 2
          },
          {
           type:"input",
           name: "stairs",
           label: "Esc.",
           col: 2
          },
          {
           type:"input",
           name: "door",
           label: "Puerta",
           col: 2
          },
          {
           type:"input",
           name: "floor",
           label: "Piso",
           col: 2
          },
          {
           type:"input",
           name: "city",
           label: "Ciudad",
           col: 4
          },
          {
           type:"input",
           name: "country",
           label: "Pais",
           col: 4
          },
          {
           type:"input",
           name: "postalcode",
           label: "C.P.",
           col: 4
          },
          {
           type:"image",
           name: "picture",
           label: "Imagen",
           col: 4
         },
          {
           type:"title",
           name: "bildata",
           label: "Datos facturación.",
           col: 12
          },
          {
           type:"input",
           name: "serieBill",
           label: "Serie",
           col: 4
          },
          {
           type:"input",
           name: "numBill",
           label: "Nº de Inicio",
           col: 4
          },

    ];

  const recoveryElement = function(objectedit,element){


    objectedit['email']=element.email?element.email:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['phone']=element.phone?element.phone:"";
    objectedit['picture']=element.picture?element.picture:"";
    objectedit['street']=element.street?element.street:"";
    objectedit['number']=element.number?element.number:"";
    objectedit['door']=element.door?element.door:"";
    objectedit['floor']=element.floor?element.floor:"";
    objectedit['stairs']=element.stairs?element.stairs:"";
    objectedit['city']=element.city?element.city:"";
    objectedit['country']=element.country?element.country:"";
    objectedit['postalcode']=element.postalcode?element.postalcode:"";
    objectedit['textTicket']=element.textTicket?element.textTicket:"";
    objectedit['serieBill']=element.serieBill?element.serieBill:"";
    objectedit['numBill']=element.numBill?element.numBill:"";



    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }




  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar Clinica"}
        titleNew = {"Nueva Clinica"}
        urledit={"/clinics/edit/"}
        urlCancel={"/clinics"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}

import React, {  useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Form from "../form/Form"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 300,
  },
}));

export default function Filter(props) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(props.filter?props.filter:{});
  const [estructure, setEstructure] = React.useState(props.estructure?props.estructure:[]);
  const [hiddenButton, setHiddenButton] = React.useState(props.hiddenButton?props.hiddenButton:false);
  const [labelsearch, setLabelsearch] = React.useState(props.labelsearch?props.labelsearch:"Buscar");
  const [labelclean, setLabelclean] = React.useState(props.labelclean?props.labelclean:"Limpiar");
  const [labeltitle, setLabeltitle] = React.useState(props.labeltitle?props.labeltitle:"Buscar");
  const [cleanInOK, setCleanInOK] = React.useState(props.cleanInOK?props.cleanInOK:false);
  const [moreactions,setMoreactions] = React.useState(props.moreactions ? props.moreactions : <></>)
  const [expanded, setExpanded] = React.useState(props.expanded?props.expanded : false);


  useEffect(() => {
    setFilter(props.filter);
    if(props.filter["id"] && props.filter["id"] > 0){
      setExpanded(true);
    }

  }, [props.filter]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
          {!hiddenButton?<Button component={Link} to={props.urlnew?props.urlnew:"#"} variant="contained" color="secondary" style={{marginLeft:'30px'}}> {props.titlebutton?props.titlebutton:'Crear nuevo'} </Button>:''}
          {moreactions}
        </Grid>
      </Grid>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className={classes.heading}> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>{labeltitle}</label>  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Form objectform={filter} fields={estructure}  searchFilter={props.searchFilter} cleanFilter={props.cleanFilter} labelsave={labelsearch} labelcancel={labelclean} cleanInOK={cleanInOK} isHiddenCancel={props.isHiddenCancel?props.isHiddenCancel:false}/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

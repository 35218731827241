import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import {patientService as service, clinicService, insuranceService} from "../../../services"
import {isAdmin,idClinic,clinicName} from '../../utils/Utils'
import CustomPaginationActionsTable from '../../table/Table';
import { Grid } from '@material-ui/core';
import Form from '../../form/Form';
import { DayTable } from '@fullcalendar/daygrid';

const initDocument =  {
    name : "",
    file: ""
};



export default function Document(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows,setRows] = useState([{}]);
    const [rowsTotal,setRowsTotal] = useState(0);
    const [document,setDocument] = useState({...initDocument});

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
  ];


  const  estructureform  =  [
    {
      type:"input",
      name: "name",
      label: "Nombre",
      col: 4
     },
    {
     type:"file",
     name: "file",
     label: "Archivo",
     col: 4
    }
];


  const servicescustom = () => {
    service.searchDocuments(props.id, (data,error) => {
        if(data){
            console.log(data);
            setRows(data.data);
            setRowsTotal(data.total);
          } else {
            setRows([{}]);
            setRowsTotal(0);
          }
    });
  }

  const deleteHandler = (idcouemnt) => {
    service.deleteDocument(props.id, idcouemnt, (data,error) => {
        servicescustom();
    });
  }

  const saveForm = (object) => {
        service.saveFileDocuments(props.id, object, (data,error) => {

            if (data && data.data && data.data.id){
                servicescustom();
                setDocument({
                    name : "",
                    file: ""
                });
            } else {

            }

        });

  }
  
  const cancelForm = (object) => {
    setDocument({
        name : "",
        file: ""
    });

  }


  useEffect(() => {
    servicescustom();
  }, []);

  return (
    <Grid container>
        <Grid xs={12}>
            <div style={{border: "1px solid #ccc", borderRadius: "6px", padding: "1em"}}>
                <h3>Subir documento</h3>
                <Form objectform={document} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave={"Guardar"} labelcancel={"Limpiar"} haveMoreActions={false} moreActions={null} forcesave={false} cleanInOK={true}/>
             </div>
        </Grid>
        <Grid xs={12}>
            <CustomPaginationActionsTable rows={rows} fields={["name","file"]} fieldId={"id"} rowsPerPage={10} rowsTotal={rowsTotal} hiddenpagination={true} headers={["Nombre","Archivo"]} page={page} deleteaction={true} deleteactionHandler={deleteHandler} editable={false}/>
        </Grid>
      </Grid>
  );
}

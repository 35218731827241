import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {treatmentService as service} from "../../../services"

const initFilter =  {
    name : null,
    email: null,
    city:null,
};

export default function Treatment() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      }
  ];



  return (
    <CrudTable
          service={service}
          titlePage={"Tratamiento"}
          titleButton={"Nuevo tratamiento"}
          urledit={"/treatment/edit/"}
          urlnew={"/treatment/new/"}
          filter={{...initFilter}}
          fieldstable={["id","name","description"]}
          headersTable={["#","Nombre","Descripcion"]}
          estructureForm={createEstructureFilter}
      />
  );
}

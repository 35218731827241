import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CrudTable from '../../crud/CrudTable';
import { billService as service, userService, clinicService, insuranceService, patientService } from '../../../services';
import { Button, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Print, Refresh } from '@material-ui/icons';

const initFilter = {
      name: null,
      email: null,
      phone: null,
      clinicId: null,
      idInsurance: null,
      userId: null,
      patientId: null,
};

export default function Bill() {
      const [filter, setFilter] = useState({ ...initFilter });

      const createEstructureFilter = [
            {
                  type: 'input',
                  name: 'number',
                  label: 'Nº',
                  inputtype: 'number',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'from',
                  label: 'Desde',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'to',
                  label: 'Hasta',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'idUser',
                  label: 'Paciente',
                  service: patientService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'clinicId',
                  label: 'Clinica',
                  service: clinicService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'idInsurance',
                  label: 'Aseguradora',
                  service: insuranceService.combofilter,
                  col: 4,
            },
      ];

      const clickRectify = (id) => {
            service.rectify(id, (data, error) => {
                  if (data && data.data) {
                        window.location.href = '/bill/edit/' + data.data['id'];
                  }
            });
      };

      const callPrintAll = () => {
            service.allFilterPrint(filter, (data, error) => {});
      };

      const exportFilter = (filter) => {
            return (
                  <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '15px', color: 'white', background: '#40906b', textTransform: 'capitalize' }} onClick={() => callPrintAll()}>
                        {' '}
                        Imprimir todas
                  </Button>
            );
      };

      return (
            <CrudTable
                  service={service}
                  titlePage={'Factura'}
                  titleButton={'Nueva Factura'}
                  urledit={'/bill/edit/'}
                  urlnew={'/bill/new/'}
                  hiddenButton={false}
                  exportbtn={true}
                  filter={filter}
                  fieldstable={['date', 'serieNumber', 'client.name', 'insurance.name', 'clinic.name', 'total']}
                  headersTable={['Fecha', 'Nº', 'Paciente', 'Aseguradora', 'Clinica', 'Total']}
                  exportfields={['serieNumber', 'date', 'client.nif', 'client.name', 'insurance.nif', 'insurance.name', 'base', 'irpf', 'total']}
                  exportlabels={['Nº', 'Fecha', 'NIF Paciente', 'Nombre paciente', 'CIF Aseguradora', 'Nombre aseguradora', 'Base Imp.', 'Irpf', 'Total']}
                  estructureForm={createEstructureFilter}
                  deleteaction={true}
                  exportactions={exportFilter}
                  moreactionstable={[
                        {
                              color: 'primary',
                              action: (id) => {
                                    service.print(id, 'factura' + id + '.pdf', (data, error) => {});
                              },
                              icon: <Print />,
                        },

                        {
                              color: 'primary',
                              action: (id) => {
                                    clickRectify(id);
                              },
                              icon: (
                                    <Tooltip title="Generar rectificativa">
                                          <Refresh />
                                    </Tooltip>
                              ),
                        },
                  ]}
                  moreactions={
                        <Button component={Link} to={'/bill/generate'} variant="contained" color="primary" style={{ marginLeft: '30px' }}>
                              {' '}
                              Generar facturas{' '}
                        </Button>
                  }
            />
      );
}

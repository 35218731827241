import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {holidaysService as service, clinicService} from "../../../services"
import {isAdmin,idClinic,clinicName} from '../../utils/Utils'




export default function Holiday() {

  const [optionsyear, setOptionsyear] = React.useState(() => {
    var currentTime = new Date();
    var year = currentTime.getFullYear();

    var valuesfields = Array();
    valuesfields.push({key:year+1, name:(year+1)+""});
    valuesfields.push({key:year, name:year+""});
    valuesfields.push({key:(year-1), name:(year-1)+""});
    valuesfields.push({key:(year-2), name:(year-2)+""});
    valuesfields.push({key:(year-3), name:(year-3)+""});
    valuesfields.push({key:(year-4), name:(year-4)+""});


    return valuesfields;
  });

  const initFilter =  {
      idClinic : idClinic(),
      year: (new Date()).getFullYear()
  };

  const createEstructureFilter =  [
      {
       type:"autocomplete",
       name: "idClinic",
       label: "Clinica",
       service:clinicService.combo,
       readonly:!isAdmin(),
       col: 4
      },
      {
       type:"autocomplete",
       name: "year",
       label: "Año",
       options: optionsyear,
       col: 4
      },
  ];


    const createEstructureFilterLaundry =  [
        {
         type:"autocomplete",
         name: "year",
         label: "Año",
         options: optionsyear,
         col: 4
        },
    ];



  return (
    <CrudTable
          service={service}
          titlePage={"Vacaciones"}
          titleButton={"Nuevo periodo vacacional"}
          urledit={"/holiday/edit/"}
          urlnew={"/holiday/new/"}
          filter={initFilter}
          fieldstable={isAdmin()?["id","clinic.name","startDate","endDate"]:["id","startDate","endDate"]}
          headersTable={isAdmin()?["#","Clinica", "Inicio","Fin"]:["#", "Inicio","Fin"]}
          estructureForm={isAdmin()?createEstructureFilter:createEstructureFilterLaundry}
      />
  );

}

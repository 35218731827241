import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';
import CustomPaginationActionsTable from '../../table/Table';
import { Grid } from '@material-ui/core';
import Form from '../../form/Form';
import { DayTable } from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { appointmentService as service, userService, clinicService, insuranceService, patientService, treatmentService } from '../../../services';
import timeGridPlugin from '@fullcalendar/timegrid';
const moment = require('moment');

const initFilter = {
      name: null,
      email: null,
      phone: null,
      clinicId: null,
      insuranceId: null,
      userId: null,
      patientId: null,
      start: '',
      end: '',
};

export default function AppointmentPatient(props) {
      const [filter, setFilter] = useState({ ...initFilter, ...{ patientId: props.id } });
      const [events, setEvents] = useState([]);

      const createEstructureFilter = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 4,
            },
      ];

      const estructureform = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 4,
            },
            {
                  type: 'file',
                  name: 'file',
                  label: 'Archivo',
                  col: 4,
            },
      ];
      const searchWeek = () => {
            service.eventsWeek(filter, (data, error) => {
                  if (data) {
                        setEvents(data.data);
                  }
            });
      };

      const search = () => {
            service.events(filter, (data, error) => {
                  if (data) {
                        setEvents(data.data);
                  }
            });
      };

      useEffect(() => {
            debugger;
            if (filter.start != null) {
                  search();
            } else {
                  searchWeek();
            }
      }, [filter]);

      const paintSpecialChar = (arg) => {
            debugger;
            return (
                  <div style={{ padding: '4px' }}>
                        {arg.event._def.extendedProps['PAY'] && arg.event._def.extendedProps['PAY'] != '' && (
                              <span>
                                    {' '}
                                    <b
                                          style={{
                                                color: 'green',
                                                background: 'white',
                                                display: 'inline-flex',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '9px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                justifyItems: 'center',
                                                borderRadius: '10px',
                                                marginRight: '5px',
                                          }}
                                    >
                                          P
                                    </b>
                              </span>
                        )}

                        {arg.event._def.extendedProps['ATYPE'] && arg.event._def.extendedProps['ATYPE'] != '' && (
                              <span>
                                    {' '}
                                    <b
                                          style={{
                                                color: 'white',
                                                background: arg.backgroundColor,
                                                display: 'inline-flex',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '9px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                justifyItems: 'center',
                                                borderRadius: '10px',
                                                marginRight: '5px',
                                          }}
                                    >
                                          {arg.event._def.extendedProps['ATYPE']}
                                    </b>
                              </span>
                        )}
                        <span>{arg.event._def.title}</span>
                  </div>
            );
      };

      const constChangeRangeDate = (events) => {
            debugger;
            var havesearch = false;
            var update = {
                  start: '',
                  end: '',
            };
            if (events.start) {
                  update['start'] = moment(events.start).format('YYYY-MM-DD') + 'T00:00:00Z';
            }
            if (events.end) {
                  update['end'] = moment(events.end).format('YYYY-MM-DD') + 'T23:59:00Z';
            }

            if (update['start'] != '') {
                  setFilter((currentFilter) => ({ ...currentFilter, ...update }));
            }
      };

      return (
            <Grid container>
                  <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '2em' }}>
                        <span style={{ background: '#6c89e5', display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px' }}> </span>
                        <span style={{ marginRight: '10px' }}>Cancelada</span>
                        <span style={{ display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px', background: '#ff0000' }}> </span>
                        <span style={{ marginRight: '10px' }}>No se prensento</span>
                        <span style={{ display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px', background: '#bfd1d1' }}> </span>
                        <span style={{ marginRight: '10px' }}>Recuperación</span>
                        <span style={{ display: 'inline-block', marginRight: '5px', width: '20px', height: '20px', borderRadius: '5px', background: '#83c63b' }}> </span>
                        <span style={{ marginRight: '10px' }}>Activa</span>
                  </Grid>
                  <Grid xs={12}>
                        <FullCalendar
                              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                              headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: window.innerWidth < 760 ? '' : 'timeGridWeek,timeGridDay,dayGridMonth',
                              }}
                              titleFormat={{ year: 'numeric', month: '2-digit', day: '2-digit' }}
                              buttonText={{
                                    today: 'Hoy',
                                    month: 'Mes',
                                    week: 'Semana',
                                    day: 'Día',
                                    list: 'List',
                                    allDay: '',
                              }}
                              timeZone="local"
                              slotMinTime={'09:00:00'}
                              slotMaxTime={'21:00:00'}
                              slotMinWidth={15}
                              allDaySlot={false}
                              contentHeight={800}
                              initialView={window.innerWidth < 760 ? 'timeGridDay' : 'dayGridMonth'}
                              firstDay={1}
                              scrollTime={8}
                              editable={false}
                              selectable={false}
                              selectMirror={false}
                              dayMaxEvents={false}
                              weekends={false}
                              locale="es"
                              events={events}
                              datesSet={constChangeRangeDate}
                              eventContent={paintSpecialChar}
                        />
                  </Grid>
            </Grid>
      );
}

import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../crud/CrudForm';
import { configurationService as service } from '../../../services';

const initElement = {
      numBill: null,
      serieBill: '',
      price: null,
};

export default function FormConfiguration() {
      const [objectform, setObjectform] = React.useState(initElement);

      const estructureform = [
            {
                  type: 'input',
                  name: 'numBill',
                  inputtype: 'number',
                  label: 'Nº de Factura',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'serieBill',
                  label: 'Serie',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  inputtype: 'number',
                  label: 'Precio',
                  step: 0.01,
                  col: 4,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['numBill'] = element.numBill ? element.numBill : '';
            objectedit['serieBill'] = element.serieBill ? element.serieBill : '';
            objectedit['price'] = element.price ? element.price : '';

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['price']) {
                  toast.notify('Debe indicar el precio', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['serieBill']) {
                  toast.notify('Debe indicar la serie', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['numBill']) {
                  toast.notify('Debe indicar el numero de factura inicial', { position: 'top', duration: 10000 });
                  return false;
            }
            return true;
      };

      return (
            <CrudForm
                  objectform={initElement}
                  estructureform={estructureform}
                  titleEdit={'Editar Configuración'}
                  titleNew={'Nueva Configuración'}
                  urledit={'/configuration/edit/'}
                  urlCancel={'/configuration/edit/1'}
                  service={service}
                  recoveryElement={recoveryElement}
                  valid={isValid}
            />
      );
}

import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../layout/Header';
import Menu from '../layout/Menu';
import Copyright from '../layout/Copyright';
import { useStyles,drawerWidth } from "../layout/styles";
import CustomPaginationActionsTable from "../table/Table"
import Filter from "../filter/Filter"
import Form from "../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';


export default function CrudForm(props) {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [objectform, setObjectform] = useState(props.objectform? props.objectform : {});
  const titleEdit = props.titleEdit? props.titleEdit : "Editar Elemento";
  const titleNew = props.titleNew? props.titleNew : "Nuevo Elemento";
  const service =  props.service?props.service:null;
  const recoveryElement =  props.recoveryElement?props.recoveryElement:null;
  const valid =  props.valid?props.valid:null;
  const urlCancel =  props.urlCancel?props.urlCancel:"/dashboard";
  const urledit =  props.urledit?props.urledit:"";
  const buttonSave =  props.buttonSave?props.buttonSave:"Guardar";
  const buttonCancel =  props.buttonCancel?props.buttonCancel:"Cancel";
  const [estructureform,setEstructureform]  = useState(props.estructureform? props.estructureform : {});
  const [haveother,setHaveother]  = useState(props.haveother? props.haveother : false);
  const [othersform,setOthersform]  = useState(props.othersform? props.othersform : null);

  const [titleWindow, setTitleWindow] = useState(id && id > 0? titleEdit:titleNew);
  const [idRow, setIdrow] = useState(id);
  const [idSerie, setIdSerie] = useState(-1);
  const [cities,setCities] = useState([]);
  const [reload,setReload] = useState(false);

  const [open, setOpen] =useState(false);
  const [rows,setRows] = useState([{}]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
      };

      setTitleWindow(idRow && idRow > 0? titleEdit:titleNew);

  }, [idRow]);

  const searchOne = function(){
    service.one(idRow,(data, error) => {

      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(element));
        if (recoveryElement) {
          objectedit = recoveryElement(objectedit,element)
          setObjectform(objectedit);
          setReload(true);
        } else {
          setObjectform(element);
          setReload(true);
        }

     }
    });
  }

  const cancelForm = (object) => {
      history.push(urlCancel);
  }

  const saveForm = (object) => {
       if(valid == null || valid(object)) {
         if(props.beforeSave) {
           object = props.beforeSave(object);
         }
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setIdrow(data.data.id)
          toast.notify("Guardado correctamente", {position:'top', duration: 10000})
          history.push(urledit+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    service.edit(id,object, (data, error) => {
        if(data){
          setObjectform(data.data);
        }
    });
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }


  useEffect(() => {

    setEstructureform(props.estructureform);

  }, [props.estructureform]);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer}>
              <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave={buttonSave} labelcancel={buttonCancel} reload={reload} haveMoreActions={props.moreActions?props.moreActions:false} moreActions={props.moreActions?props.moreActions:null} forcesave={props.forcesave != null? props.forcesave : false}/>
              </Grid>
              {haveother && idRow > 0 && othersform}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

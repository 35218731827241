import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { isAdmin, idClinic, clinicName } from '../utils/Utils';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import StorageIcon from '@material-ui/icons/Storage';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import { faCalendarDay, faCalendarAlt, faBusinessTime, faClinicMedical, faUniversity, faPills, faUserInjured, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

//import { dashList,lensList, secondaryListItems } from './listMenu';

export default function Header(props) {
      const [openAttrLens, setOpenAttrLens] = React.useState(false);

      const closeAll = (id) => {
            if (id != 1) {
                  setOpenProduct(false);
            } else {
                  setOpenProduct(!openProduct);
            }
            if (id != 2) {
                  setOpenMaestros(false);
            } else {
                  setOpenMaestros(!openMaestros);
            }
      };

      const [openProduct, setOpenProduct] = React.useState(false);
      const [openMaestros, setOpenMaestros] = React.useState(false);

      return (
            <Drawer
                  variant="permanent"
                  classes={{
                        paper: clsx(props.classes.drawerPaper, !props.open && props.classes.drawerPaperClose),
                  }}
                  open={props.open}
            >
                  <div className={props.classes.toolbarIcon}>
                        <IconButton onClick={props.handleDrawerClose}>
                              <ChevronLeftIcon />
                        </IconButton>
                  </div>
                  <Divider />
                  <List>
                        <Link to="/dashboard" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Agenda" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/appointment" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faBusinessTime} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Buscar de citas" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  {isAdmin() && (
                        <List>
                              <Link to="/clinics/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faClinicMedical} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Clinicas" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  {isAdmin() && (
                        <List>
                              <Link to="/insurance/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faUniversity} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Aseguradoras" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}

                  <List>
                        <Link to="/treatment/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faPills} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Tratamientos" />
                              </ListItem>
                        </Link>
                  </List>

                  <Divider />
                  {isAdmin() && (
                        <List>
                              <Link to="/holiday/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faCalendarDay} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Dias no laborables" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  <List>
                        <Link to="/patient/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faUserInjured} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Pacientes" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  {isAdmin() && (
                        <List>
                              <Link to="/bill/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Facturas" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  {isAdmin() && (
                        <List>
                              <Link to="/administrator/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <GroupIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Administradores" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  {isAdmin() && (
                        <List>
                              <Link to="/configuration/edit/1" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <BuildIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Configuracion" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
            </Drawer>
      );
}

import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {treatmentService as service} from "../../../services"

const initElement = {
     name:"",
     description: "",
  };


export default function FormTreatment() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);


  const estructureform =  [
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 12
          },
          {
           type:"textarea",
           name: "description",
           label: "Descripción",
           col: 12,
          }
    ];

  const recoveryElement = function(objectedit,element){

    objectedit['name']=element.name?element.name:"";
    objectedit['description']=element.description?element.description:"";

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar Tratamiento"}
        titleNew = {"Nuevo Tratamiento"}
        urledit={"/treatment/edit/"}
        urlCancel={"/treatment"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}

import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {userService as service} from "../../../services"

const initFilter =  {
    name : null,
    email: null,
    phone:null,
};

export default function Administrator() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
     {
       type:"input",
       name: "email",
       label: "Email",
       col: 4
      },
       {
         type:"input",
         name: "phone",
         label: "Tlf.",
         col: 4
        },
  ];




  return (
    <CrudTable
          service={service}
          titlePage={"Usuarios"}
          titleButton={"Nuevo usuario"}
          urledit={"/administrator/edit/"}
          urlnew={"/administrator/new/"}
          filter={initFilter}
          fieldstable={["picture","email","name","lastname","rol.name"]}
          headersTable={["#","Email","Nombre", "Apellido","Rol"]}
          estructureForm={createEstructureFilter}
      />
  );
}

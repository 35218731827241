import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {userService as service, clinicService} from "../../../services"

const initElement = {
     email:"",
     pass: "",
     name: "",
     lastname: "",
     isClient:false,
     rol: {code:null,name:""},
     enable: true,
     clinic:{id:null,name:""},
     picture:null
  };


export default function FormAdministrator() {
  const [objectform, setObjectform] = React.useState(initElement);
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);


  const estructureform =  [
          {
           type:"input",
           name: "email",
           label: "Email",
           readonly:idRow > 0?true:false,
           col: 4
          },
          {
           type:"input",
           name: "pass",
           label: "Contraseña",
           col: 4,
           inputtype: "password"
          },
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"input",
           name: "lastname",
           label: "Apellido",
           col: 4
          },
          {
           type:"input",
           name: "phone",
           label: "Teléfono",
           col: 4
          },
          {
           type:"autocomplete",
           name: "rol.code",
           label: "Rol",
           col: 4,
           options:[{key:"ADMIN",name:"ADMIN"},{key:"FISIO",name:"FISIO"}]
          },
          {
           type:"autocomplete",
           name: "clinic.id",
           label: "Clinica",
           service:clinicService.combo,
           col: 4,
          },
          {
           type:"image",
           name: "picture",
           label: "Imagen",
           col: 3
          }
    ];

  const recoveryElement = function(objectedit,element){


    objectedit['email']=element.email?element.email:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['phone']=element.phone?element.phone:"";
    objectedit['lastname']=element.lastname?element.lastname:"";
    objectedit['rol']={code:element.rol && element.rol.code ?element.rol.code:null, name: element.rol && element.rol.name?element.rol.name:null};
    objectedit['clinic']={id:element.clinic && element.clinic.id ?element.clinic.id:null, name: element.clinic && element.clinic.name?element.clinic.name:null};
    objectedit['picture']=element.picture?element.picture:"";

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['email']){
        toast.notify("Debe indicar el email", {position:'top', duration: 10000})
        return false;
      }
      if(!object['rol'] && !object['rol']['code']){
        toast.notify("Debe indicar el rol", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar Usuario"}
        titleNew = {"Nuevo Usuario"}
        urledit={"/administrator/edit/"}
        urlCancel={"/administrator"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}

import React from 'react';
import {BrowserRouter as Router, Route, Redirect,Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import Login from './Login';
import Dashboard from './component/page/dashboard/Dashboard';
import Administrator from './component/page/user/Administrator';
import FormAdministrator from './component/page/user/FormAdministrator';
import Clinic from './component/page/clinic/Clinic';
import FormClinic from './component/page/clinic/FormClinic';
import Treatment from './component/page/treatment/Treatment';
import FormTreatment from './component/page/treatment/FormTreatment';
import Holiday from './component/page/holiday/Holiday';
import FormHoliday from './component/page/holiday/FormHoliday';
import Insurance from './component/page/insurance/Insurance';
import FormInsurance from './component/page/insurance/FormInsurance';
import Patient from './component/page/patient/Patient';
import FormPatient from './component/page/patient/FormPatient';
import FormConfiguration from './component/page/configuration/FormConfiguration';
import Appointment from './component/page/appointment/Appointment';
import FormAppointment from './component/page/appointment/FormAppointment';
import Bill from './component/page/bill/Bill';
import FormBill from './component/page/bill/FormBill';
import GenerateBill from './component/page/bill/GenerateBill';





import jwtDecode from 'jwt-decode';



interface TokenInfo {
  exp: number;
}

function isLogin(){
  var expisold = false;
  if(localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined && localStorage.getItem("token") != "undefined"){
    const token:string = localStorage.getItem("token")+'';
    const tokende =  jwtDecode<TokenInfo>(token);
    if (Date.now() >= tokende.exp * 1000) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export default function App() {

  const isLoggedNotIn = isLogin();

  return (
    <>
    <Router>
      <Switch>
        <LoginRoute path="/login" component={Login} />
        <PrivateRoute path="/dashboard/" component={Dashboard} />
        <PrivateRoute path="/administrator/new" component={FormAdministrator} />
        <PrivateRoute path="/administrator/edit/:id" component={FormAdministrator} />
        <PrivateRoute path="/administrator/" component={Administrator} />
        <PrivateRoute path="/clinics/new" component={FormClinic} />
        <PrivateRoute path="/clinics/edit/:id" component={FormClinic} />
        <PrivateRoute path="/clinics/" component={Clinic} />
        <PrivateRoute path="/treatment/new" component={FormTreatment} />
        <PrivateRoute path="/treatment/edit/:id" component={FormTreatment} />
        <PrivateRoute path="/treatment/" component={Treatment} />
        <PrivateRoute path="/holiday/new" component={FormHoliday} />
        <PrivateRoute path="/holiday/edit/:id" component={FormHoliday} />
        <PrivateRoute path="/holiday/" component={Holiday} />
        <PrivateRoute path="/insurance/new" component={FormInsurance} />
        <PrivateRoute path="/insurance/edit/:id" component={FormInsurance} />
        <PrivateRoute path="/insurance/" component={Insurance} />
        <PrivateRoute path="/patient/edit/:id" component={FormPatient} />
        <PrivateRoute path="/patient/new" component={FormPatient} />
        <PrivateRoute path="/patient/" component={Patient} />
        <PrivateRoute path="/configuration/edit/:id" component={FormConfiguration} />
        <PrivateRoute path="/appointment/edit/:id" component={FormAppointment} />
        <PrivateRoute path="/appointment/new" component={FormAppointment} />
        <PrivateRoute path="/appointment/" component={Appointment} />
        <PrivateRoute path="/bill/edit/:id" component={FormBill} />
        <PrivateRoute path="/bill/new" component={FormBill} />
        <PrivateRoute path="/bill/generate" component={GenerateBill} />
        <PrivateRoute path="/bill/" component={Bill} />

        
        
        {isLoggedNotIn ? <Route path="/" component={Login} /> : <PrivateRoute path="/" component={Dashboard} />}
      </Switch>
    </Router>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'react-moment';

const useStyles1 = makeStyles((theme) => ({
      root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
      },
}));

function TablePaginationActions(props) {
      const classes = useStyles1();
      const theme = useTheme();
      const { count, page, rowsPerPage, onChangePage } = props;

      const handleFirstPageButtonClick = (event) => {
            onChangePage(event, 0);
      };

      const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
            <div className={classes.root}>
                  <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                  </IconButton>
                  <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  </IconButton>
                  <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}{' '}
                  </IconButton>
                  <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}{' '}
                  </IconButton>
            </div>
      );
}

TablePaginationActions.propTypes = {
      count: PropTypes.number.isRequired,
      onChangePage: PropTypes.func.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
      table: {
            minWidth: 500,
      },
      tablecontainer: {
            boxShadow: 'none',
      },
      bodymodal: {
            margin: 'auto',
            width: '500px',
            maxWidth: 'calc(100vh - 40px)',
            background: 'white',
            padding: '5em',
            marginTop: '100px',
            textAlign: 'center',
      },
});

export default function CustomPaginationActionsTable(props) {
      const classes = useStyles2();
      const [page, setPage] = React.useState(props.page);
      const [idrow, setIdrow] = React.useState('');
      const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 5);
      const [deleteaction, setDeleteaction] = React.useState(props.deleteaction ? props.deleteaction : false);
      const [iconDeleteAction, setIconDeleteAction] = React.useState(props.iconDeleteAction ? props.iconDeleteAction : <DeleteIcon />);
      const [iconEditAction, setIconEditAction] = React.useState(props.iconEditAction ? props.iconEditAction : <EditIcon />);
      const [colorIconDeleteAction, setColorIconDeleteAction] = React.useState(props.colorIconDeleteAction ? props.colorIconDeleteAction : 'red');
      const [colorIconEditAction, setColorIconEditAction] = React.useState(props.colorIconEditAction ? props.colorIconEditAction : '#40906c');
      const [actionQuestion, setActionQuestion] = React.useState(props.actionQuestion ? props.actionQuestion : '¿Estas seguro que deseas borrarlo?');
      const [open, setOpen] = React.useState(false);
      const rows = props.rows;
      const rowsTotal = props.rowsTotal;
      const fields = props.fields;
      const headers = props.headers;
      const fieldId = props.fieldId;
      const handleChangePageParent = props.changePage;
      const [hiddenpagination, setHiddenpagination] = React.useState(props.hiddenpagination ? props.hiddenpagination : false);

      const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

      const handleChangePage = (event, newPage) => {
            setPage(newPage);
            handleChangePageParent(newPage, rowsPerPage);
      };

      useEffect(() => {
            setPage(props.page);
      }, [props.page]);

      const handleChangeRowsPerPage = (event) => {
            const sizePage = parseInt(event.target.value, 10);
            setRowsPerPage(sizePage);
            setPage(0);
            handleChangePageParent(0, sizePage);
      };

      const donwloadFile = (id) => {};

      const getValueField = (object, field, only = false) => {
            if (object) {
                  if (field.includes('.')) {
                        const parts = field.split('.');
                        var objfield = object[parts[0]];
                        if (Array.isArray(objfield)) {
                              var result = '';
                              for (let entry of objfield) {
                                    result = result + getValueField(entry, field.substring(parts[0].length + 1), true) + '\n';
                              }
                              return (
                                    <>
                                          {result}
                                          <br />
                                    </>
                              );
                        } else {
                              var r = getValueField(objfield, field.substring(parts[0].length + 1));
                              if (typeof r === 'boolean' && r) {
                                    return <CheckIcon style={{ color: 'green' }} />;
                              } else if (typeof r === 'boolean' && !r) {
                                    return <ClearIcon style={{ color: 'red' }} />;
                              } else if (!r || r == '' || r == null) {
                                    return <></>;
                              } else {
                                    return <>{r}</>;
                              }
                        }
                  } else {
                        if ((field == 'image' || field == 'picture' || field == 'imageprin') && object[field] && object[field] != '') {
                              const url = getUrlImage(object[field]);
                              return <img src={url} style={{ width: '70px', height: 'auto', maxWidth: '100%', minWidth: '70px' }} />;
                        } else if (field == 'file' && object[field] && object[field] != '') {
                              return (
                                    <a href={process.env.REACT_APP_HOST + '/download' + object[field]} target="_blank" style={{ color: '#40906b' }}>
                                          <AssignmentReturnedIcon style={{ fontSize: '3em' }} />
                                    </a>
                              );
                        } else if (field == 'day' && object[field] && object[field] != '') {
                              switch (object[field]) {
                                    case 1:
                                          return (
                                                <>
                                                      <label> {'Lunes'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 2:
                                          return (
                                                <>
                                                      <label> {'LunMarteses'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 3:
                                          return (
                                                <>
                                                      <label> {'Miercoles'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 4:
                                          return (
                                                <>
                                                      <label> {'Jueves'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 5:
                                          return (
                                                <>
                                                      <label> {'Viernes'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 6:
                                          return (
                                                <>
                                                      <label> {'Sabado'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 7:
                                          return (
                                                <>
                                                      <label> {'Domingo'} </label>
                                                      <br />
                                                </>
                                          );
                              }
                        } else if ((field.toLowerCase().includes('date') || field.includes('pickUp') || field.includes('delivery')) && object[field] && object[field] != '') {
                              return (
                                    <>
                                          <Moment date={object[field]} format={'DD/MM/yyyy'} />
                                          <br />
                                    </>
                              );
                        } else {
                              var r2 = object[field];
                              if (typeof r === 'boolean' && r2) {
                                    return <CheckIcon style={{ color: 'green' }} />;
                              } else if (typeof r === 'boolean' && !r2) {
                                    return <ClearIcon style={{ color: 'red' }} />;
                              } else if (!r2 || r2 == '' || r2 == null) {
                                    return <></>;
                              } else {
                                    if (only) {
                                          return r2;
                                    } else {
                                          return (
                                                <>
                                                      {r2}
                                                      <br />
                                                </>
                                          );
                                    }
                              }
                        }
                  }
            } else {
                  return '';
            }
      };

      const getUrlImage = function (namefile) {
            if (namefile) {
                  if (namefile.startsWith('http')) {
                        return namefile;
                  } else {
                        var nameImage = namefile;
                        nameImage = nameImage.startsWith('/') ? nameImage : '/' + nameImage;
                        return process.env.REACT_APP_HOST + '/image' + nameImage;
                  }
            } else {
                  return '';
            }
      };

      const handlerDel = function (id) {
            console.log('DELETE ->' + id);
            setIdrow(id);
            setOpen(true);
      };

      const handlerDelConfirm = function () {
            console.log('DELETE ->' + idrow);
            setOpen(false);
            if (props.deleteactionHandler) {
                  props.deleteactionHandler(idrow);
            }
      };

      const deleteactionButton = function (row) {
            if (props.getDeleteButton) {
                  return props.getDeleteButton(row, handlerDel);
            } else {
                  return (
                        <Button variant="contained" color="default" style={{ backgroundColor: colorIconDeleteAction, color: 'white' }} onClick={() => handlerDel(row[fieldId])}>
                              {' '}
                              {iconDeleteAction}{' '}
                        </Button>
                  );
            }
      };

      const editactionButton = function (row) {
            if (props.getDeleteButton) {
                  return props.getDeleteButton(row, handlerDel);
            } else {
                  return (
                        <Button variant="contained" color="default" style={{ backgroundColor: colorIconEditAction, color: 'white' }} onClick={() => handlerDobleClick(row[fieldId])}>
                              {' '}
                              {iconEditAction}{' '}
                        </Button>
                  );
            }
      };

      const handleClose = () => {
            setOpen(false);
      };

      const handlerDobleClick = function (id) {
            if (props.selectHandler) {
                  props.selectHandler(id);
            }
      };

      const paintCell = (row, field, index) => {
            if (field.includes('|')) {
                  var fields = field.split('|');
                  var value = '';
                  return (
                        <TableCell component="td" scope="row">
                              <div>
                                    {' '}
                                    {fields.map((element) => (
                                          <>
                                                <label className="labelTableRow">{headers[index]}</label>
                                                <div className="valuecell">{getValueField(row, element)}</div>
                                          </>
                                    ))}
                              </div>{' '}
                        </TableCell>
                  );
            } else {
                  return (
                        <TableCell component="td" scope="row">
                              {' '}
                              <>
                                    <label className="labelTableRow">{headers[index]}</label>
                                    <div className="valuecell">{getValueField(row, field)}</div>
                              </>
                        </TableCell>
                  );
            }
      };

      return (
            <Grid container>
                  <Grid item sm={12} style={{ textAlign: 'right', marginBottom: '10px', width: '100%' }}>
                        <TableContainer component={Paper} className={classes.tablecontainer}>
                              <Table className={classes.table} aria-label="custom pagination table">
                                    <TableHead>
                                          <TableRow>
                                                {headers.map((h) => (
                                                      <TableCell component="th" scope="row">
                                                            {h}
                                                      </TableCell>
                                                ))}
                                                {props.editable ? <TableCell component="th" scope="row" width="40px"></TableCell> : ''}
                                                {deleteaction ? <TableCell component="th" scope="row" width="40px"></TableCell> : ''}
                                                {props.moreactionstable &&
                                                      props.moreactionstable.map((action) => {
                                                            return <TableCell component="th" scope="row" width="40px"></TableCell>;
                                                      })}
                                          </TableRow>
                                    </TableHead>
                                    <TableBody>
                                          {rows.map((row) => (
                                                <TableRow key={row[fieldId]} onDoubleClick={(event) => handlerDobleClick(row[fieldId])}>
                                                      {fields.map((field, index) => paintCell(row, field, index))}
                                                      {props.editable && row && row[fieldId] ? (
                                                            <TableCell component="td" scope="row" variant="body" width="40px">
                                                                  {editactionButton(row)}
                                                            </TableCell>
                                                      ) : (
                                                            ''
                                                      )}
                                                      {deleteaction && row && row[fieldId] ? (
                                                            <TableCell component="td" scope="row" variant="body" width="40px">
                                                                  {deleteactionButton(row)}
                                                            </TableCell>
                                                      ) : (
                                                            ''
                                                      )}
                                                      {props.moreactionstable &&
                                                            props.moreactionstable.map((action) => {
                                                                  return (
                                                                        <TableCell component="td" scope="row" variant="body" width="40px">
                                                                              <Button variant="contained" color={action.color} onClick={() => action.action(row[fieldId])}>
                                                                                    {' '}
                                                                                    {action.icon}{' '}
                                                                              </Button>
                                                                        </TableCell>
                                                                  );
                                                            })}
                                                </TableRow>
                                          ))}
                                    </TableBody>
                                    {!hiddenpagination && (
                                          <TableFooter>
                                                <TableRow>
                                                      <TablePagination
                                                            rowsPerPageOptions={[5, 10, 20, 40, 60, 80, 100, { label: 'All', value: -1 }]}
                                                            colSpan={deleteaction ? fields.length + 1 : fields.length}
                                                            count={rowsTotal}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                  inputProps: { 'aria-label': 'rows per page' },
                                                                  native: true,
                                                            }}
                                                            labelRowsPerPage={'Nº por pag.'}
                                                            onPageChange={handleChangePage}
                                                            onChangePage={handleChangePage}
                                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                      />
                                                </TableRow>
                                          </TableFooter>
                                    )}
                              </Table>
                        </TableContainer>
                  </Grid>
                  <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <div className={classes.bodymodal}>
                              <div style={{ marginBottom: '30px' }}>{actionQuestion}</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleClose}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handlerDelConfirm}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </div>
                  </Modal>
            </Grid>
      );
}

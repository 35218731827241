import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CrudTable from '../../crud/CrudTable';
import { appointmentService as service, userService, clinicService, insuranceService, patientService } from '../../../services';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';

const initFilter = {
      name: null,
      email: null,
      phone: null,
      clinicId: idClinic(),
      insuranceId: null,
      userId: null,
      patientId: null,
};

export default function Appointment() {
      const createEstructureFilter = isAdmin()
            ? [
                    {
                          type: 'input',
                          name: 'name',
                          label: 'Nombre',
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'email',
                          label: 'Email',
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'phone',
                          label: 'Tlf.',
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'clinicId',
                          label: 'Clinica',
                          service: clinicService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'insuranceId',
                          label: 'Aseguradora',
                          service: insuranceService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'userId',
                          label: 'Fisio',
                          service: userService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'patientId',
                          label: 'Paciente',
                          service: patientService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'pay',
                          label: 'Pagadas',
                          options: [
                                { id: -1, name: 'Todas' },
                                { id: 1, name: 'Pagadas' },
                                { id: 0, name: 'No pagadas' },
                          ],
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'state',
                          label: 'Estado',
                          options: [
                                { key: -1, name: 'Todas' },
                                { key: 0, name: 'Canceladas' },
                                { key: 1, name: 'No aparece' },
                                { key: 2, name: 'Correctas' },
                          ],
                          col: 4,
                    },
              ]
            : [
                    {
                          type: 'input',
                          name: 'name',
                          label: 'Nombre',
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'email',
                          label: 'Email',
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'phone',
                          label: 'Tlf.',
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'insuranceId',
                          label: 'Aseguradora',
                          service: insuranceService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'userId',
                          label: 'Fisio',
                          service: userService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'patientId',
                          label: 'Paciente',
                          service: patientService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'pay',
                          label: 'Pagadas',
                          options: [
                                { id: -1, name: 'Todas' },
                                { id: 1, name: 'Pagadas' },
                                { id: 0, name: 'No pagadas' },
                          ],
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'state',
                          label: 'Estado',
                          options: [
                                { key: -1, name: 'Todas' },
                                { key: 0, name: 'Canceladas' },
                                { key: 1, name: 'No aparece' },
                                { key: 2, name: 'Correctas' },
                          ],
                          col: 4,
                    },
              ];

      return (
            <CrudTable
                  service={service}
                  titlePage={'Citas'}
                  titleButton={'Nueva cita'}
                  urledit={'/appointment/edit/'}
                  urlnew={'/appointment/new/'}
                  filter={initFilter}
                  fieldstable={['id', 'patient.name', 'clinic.name', 'fisio.name', 'dateAppointment', 'hour']}
                  headersTable={['#', 'Paciente', 'Clinica', 'Fisio', 'Fecha', 'Hora']}
                  estructureForm={createEstructureFilter}
            />
      );
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CrudTable from '../../crud/CrudTable';
import { patientService as service, clinicService, insuranceService } from '../../../services';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';

const initFilter = {
      name: null,
      email: null,
      phone: null,
      clinicId: idClinic(),
      insuranceId: null,
};

export default function Patient() {
      const createEstructureFilter = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'email',
                  label: 'Email',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'phone',
                  label: 'Tlf.',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'clinicId',
                  label: 'Clinica',
                  service: clinicService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'insuranceId',
                  label: 'Aseguradora',
                  service: insuranceService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'invalied',
                  label: 'Datos invalidos',
                  options: [
                        { key: 1, label: 'Si' },
                        { key: -1, label: 'No' },
                        { key: 0, label: 'Ambos' },
                  ],
                  col: 4,
            },
      ];

      const createEstructureFilterFisio = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'email',
                  label: 'Email',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'phone',
                  label: 'Tlf.',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'insuranceId',
                  label: 'Aseguradora',
                  service: insuranceService.combo,
                  col: 4,
            },
      ];

      return (
            <CrudTable
                  service={service}
                  titlePage={'Paciente'}
                  titleButton={'Nuevo Paciente'}
                  urledit={'/patient/edit/'}
                  urlnew={'/patient/new/'}
                  filter={initFilter}
                  fieldstable={['name', 'insurance.name', 'diagnostico', 'clinic.name']}
                  headersTable={['Nombre', 'Aseguradora', 'Diagnostico', 'Clínica']}
                  estructureForm={isAdmin() ? createEstructureFilter : createEstructureFilterFisio}
            />
      );
}

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../crud/CrudForm';
import { billService as service, clinicService, patientService, insuranceService, configurationService } from '../../../services';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';
import { Button } from '@material-ui/core';

export default function FormBill() {
      const moment = require('moment');
      const history = useHistory();

      const initElement = {
            client: { id: null, name: '' },
            insurance: { id: null, name: '' },
            clinic: { id: idClinic(), name: null },
            date: moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00Z',
            numberBill: '',
            serie: moment(new Date()).format('YY'),
            base: 0,
            iva: 0,
            percentIva: 0,
            discount: 0,
            discountPercent: 0,
            nif: '',
            email: '',
            name: '',
            street: '',
            phone: '',
            number: '',
            door: '',
            floor: '',
            stairs: '',
            city: '',
            country: '',
            postalcode: '',
            isDraft: 'DRAFT',
            isPay: 'NOTPAY',
            notes: '',
            lines: [
                  {
                        id: -1,
                        name: 'TRATAMIENTO DE FISIOTERAPIA',
                        amount: 1.0,
                        price: 0.0,
                        total: 0.0,
                        base: 0.0,
                  },
            ],
      };

      const initline = {
            id: -2,
            name: 'TRATAMIENTO DE FISIOTERAPIA',
            amount: 1.0,
            price: 0.0,
            total: 0.0,
            base: 0.0,
      };

      const [objectform, setObjectform] = React.useState({ ...initElement });
      let { id } = useParams();
      const [forceRepait, setForceRepait] = useState(0);
      const [forcesave, setForceSave] = useState(false);
      const [configuration, setConfiguration] = useState({});

      const setValueAmout = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = +value;
            var price = rv['price'];

            var total = price * ammount;
            var base = total;
            var irpf = 0;

            var newobject = setValueField(objectform, 'base', base);
            //newobject = setValueField(newobject, 'irpf', irpf);
            newobject = setValueField(newobject, 'total', total);

            rv['base'] = newobject['base'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const setValuePrice = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = rv['amount'];
            var price = +value;

            var total = price * ammount;
            var base = total;

            var newobject = setValueField(objectform, 'base', base);
            newobject = setValueField(newobject, 'total', total);

            rv['base'] = newobject['base'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const updateRows = (setValueField, objectform, rows, setValueform) => {
            var totalLines = 0.0;

            for (let row of rows) {
                  if (row['total']) {
                        totalLines = totalLines + +row['total'];
                  }
            }

            var base = totalLines;
            var total = base.toFixed(2);
            var irpf = 0;

            if (objectform['insurance'] != null && objectform['insurance']['id'] != null && objectform['insurance']['id'] > 0) {
                  irpf = base * 0.15;
                  total = (base - irpf).toFixed(2);
            }

            setValueform((currentValueform) => ({ ...currentValueform, ['total']: total }));
            setValueform((currentValueform) => ({ ...currentValueform, ['irpf']: irpf.toFixed(2) }));
            setValueform((currentValueform) => ({ ...currentValueform, ['base']: base.toFixed(2) }));
            var newobject = setValueField(objectform, 'total', total);
            newobject = setValueField(newobject, 'base', base);
            newobject = setValueField(newobject, 'irpf', irpf);

            return newobject;
      };

      const changePatient = (setValueField, objectform, value, valueform, setValueform) => {
            if (value) {
                  var newobject = objectform;

                  patientService.one(value.key, (data, error) => {
                        var user = data.data;

                        newobject = setValueField(newobject, 'client', user);
                        newobject = setValueField(newobject, 'name', user.name);
                        newobject = setValueField(newobject, 'email', user.email);
                        newobject = setValueField(newobject, 'nif', user.nif);
                        newobject = setValueField(objectform, 'street', user.street);
                        newobject = setValueField(newobject, 'postalcode', user.postalcode);
                        newobject = setValueField(newobject, 'city', user.city);
                        newobject = setValueField(newobject, 'clinic.id', user.clinic.id);

                        setValueform((currentValueform) => ({ ...currentValueform, ['name']: user.name + ' ' + (user.lastname ? user.lastname : '') }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['nif']: user.nif ? user.nif : '' }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['email']: user.email ? user.email : '' }));

                        setValueform((currentValueform) => ({ ...currentValueform, ['street']: user.street }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['postalcode']: user.postalcode }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['city']: user.city }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['clinic.id']: { id: user.clinic?.id, name: user.clinic?.name } }));

                        setForceRepait(+moment(new Date()).format('ss'));
                  });

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const changeinsurance = (setValueField, objectform, value, valueform, setValueform) => {
            if (value) {
                  var newobject = objectform;

                  insuranceService.one(value.key, (data, error) => {
                        var insurance = data.data;

                        newobject = setValueField(newobject, 'insurance', insurance);
                        newobject = setValueField(newobject, 'name', insurance.name);
                        newobject = setValueField(newobject, 'email', insurance.email);
                        newobject = setValueField(newobject, 'nif', insurance.nif);
                        newobject = setValueField(objectform, 'street', insurance.street);
                        newobject = setValueField(newobject, 'postalcode', insurance.postalcode);
                        newobject = setValueField(newobject, 'city', insurance.city);

                        setValueform((currentValueform) => ({ ...currentValueform, ['name']: insurance.name + ' ' + (insurance.lastname ? insurance.lastname : '') }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['nif']: insurance.nif ? insurance.nif : '' }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['email']: insurance.email ? insurance.email : '' }));

                        setValueform((currentValueform) => ({ ...currentValueform, ['street']: insurance.street }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['postalcode']: insurance.postalcode }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['city']: insurance.city }));

                        setForceRepait(+moment(new Date()).format('ss'));
                  });

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const initlinePrice = (line, objectform) => {
            debugger;
            if (line['price'] == null || line['price'] == 0) {
                  if (objectform.insurance != null && objectform.insurance.price != null) {
                        line['price'] = objectform.insurance.price;
                  } else if (objectform.client != null && objectform.client.specialPrice != null) {
                        line['price'] = objectform.client.specialPrice;
                  } else {
                        line['price'] = configuration['price'];
                  }
                  line['amount'] = 1;
                  line['base'] = line['price'];
                  line['total'] = line['price'];
            }

            return line;
      };

      const estructureformline = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Concepto',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'amount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Cantidad',
                  setvalue: setValueAmout,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Precio (Con IVA)',
                  setvalue: setValuePrice,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Total (Con IVA)',
                  readonly: true,
                  col: 3,
            },
      ];

      const estructureform = [
            {
                  type: 'input',
                  name: 'date',
                  label: 'Fecha',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'serie',
                  label: 'Serie',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'numberBill',
                  label: 'Nº',
                  readonly: true,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'client.id',
                  label: 'Paciente',
                  service: patientService.combo,
                  setvalue: changePatient,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'insurance.id',
                  label: 'Aseguradora',
                  service: insuranceService.combo,
                  setvalue: changeinsurance,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'clinic.id',
                  label: 'Clinica',
                  service: clinicService.combo,
                  readonly: !isAdmin(),
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'isDraft',
                  label: 'Estado',
                  options: [
                        { key: 'DRAFT', label: 'Borrador' },
                        { key: 'GENERATE', label: 'Generada' },
                  ],
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'isPay',
                  label: '¿Pagada?',
                  options: [
                        { key: 'NOTPAY', label: 'No pagada' },
                        { key: 'PAY', label: 'Pagada' },
                  ],
                  col: 3,
            },
            {
                  type: 'title',
                  name: 'titledata',
                  label: 'Datos de facturación',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'nif',
                  label: 'Nif/Cif',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'email',
                  label: 'Email',
                  col: 3,
            },
            {
                  type: 'title',
                  name: 'titledatastreet',
                  label: 'Dirección de facturación',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'street',
                  label: 'Calle',
                  col: 6,
            },
            {
                  type: 'input',
                  name: 'postalcode',
                  label: 'C.P.',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'city',
                  label: 'Ciudad',
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titledataconcept',
                  label: 'Conceptos a facturar',
                  col: 12,
            },
            {
                  type: 'table',
                  name: 'lines',
                  label: 'Lineas',
                  headers: [
                        { label: 'Concepto', colSpan: '50%' },
                        { label: 'Cantidad', colSpan: '15%' },
                        { label: 'Precio', colSpan: '15%' },
                        { label: 'Total', colSpan: '20%' },
                  ],
                  initrow: initline,
                  fields: estructureformline,
                  fieldId: 'id',
                  setvalue: updateRows,
                  initline: initlinePrice,
                  forceRepait: forceRepait,
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'base',
                  label: 'Base Impoonible',
                  inputtype: 'number',
                  col: 4,
                  readonly: true,
            },
            {
                  type: 'input',
                  name: 'irpf',
                  label: 'Iprf',
                  inputtype: 'number',
                  col: 4,
                  readonly: true,
            },
            {
                  type: 'input',
                  name: 'total',
                  label: 'Total',
                  inputtype: 'number',
                  col: 4,
                  readonly: true,
            },

            {
                  type: 'textarea',
                  name: 'notes',
                  label: 'Notas / Observaciones',
                  col: 12,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['client'] = element.client != null && element.client.id != null ? { id: element.client.id, name: element.client.name } : { id: null, name: '' };
            objectedit['insurance'] = element.insurance && element.insurance.id != null ? { id: element.insurance.id, name: element.insurance.name } : { id: null, name: '' };
            objectedit['clinic'] = element.clinic && element.clinic.id != null ? { id: element.clinic.id, name: element.clinic.name } : { id: null, name: '' };
            objectedit['numberBill'] = element['numberBill'] ? element['numberBill'] : '';
            objectedit['serie'] = element['serie'] ? element['serie'] : '';
            objectedit['base'] = element['base'] ? element['base'] : 0;
            objectedit['iva'] = element['iva'] ? element['iva'] : 0;
            objectedit['percentIva'] = element['percentIva'] ? element['percentIva'] : 0;
            objectedit['discount'] = element['discount'] ? element['discount'] : 0;
            objectedit['discountPercent'] = element['discountPercent'] ? element['discountPercent'] : 0;
            objectedit['nif'] = element['nif'] ? element['nif'] : '';
            objectedit['email'] = element['email'] ? element['email'] : '';
            objectedit['name'] = element['name'] ? element['name'] : '';
            objectedit['street'] = element['street'] ? element['street'] : '';
            objectedit['phone'] = element['phone'] ? element['phone'] : '';
            objectedit['number'] = element['number'] ? element['number'] : '';
            objectedit['door'] = element['door'] ? element['door'] : '';
            objectedit['floor'] = element['floor'] ? element['floor'] : '';
            objectedit['stairs'] = element['stairs'] ? element['stairs'] : '';
            objectedit['city'] = element['city'] ? element['city'] : '';
            objectedit['country'] = element['country'] ? element['country'] : '';
            objectedit['postalcode'] = element['postalcode'] ? element['postalcode'] : '';
            objectedit['isDraft'] = element['isDraft'] != null ? element['isDraft'] : 'DRAFT';
            objectedit['isPay'] = element['isPay'] != null ? element['isPay'] : 'NOTPAY';
            objectedit['lines'] = element['lines'] != null ? element['lines'] : [];
            objectedit['notes'] = element['notes'] ? element['notes'] : '';

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['client'] || !object['client']['id']) {
                  toast.notify('Debe indicar el paciente', { position: 'top', duration: 10000 });
                  setForceSave(false);
                  return false;
            }
            if (!object['clinic'] || !object['clinic']['id']) {
                  toast.notify('Debe indicar la clinica', { position: 'top', duration: 10000 });
                  setForceSave(false);
                  return false;
            }
            if (!object['lines'] || object['lines'].length == 0) {
                  toast.notify('Debe indicar algun concepto a facturar', { position: 'top', duration: 10000 });
                  setForceSave(false);
                  return false;
            }

            return true;
      };

      const clickGenrate = (setValueField, objectform, valueform, setValueform, setObjectform) => {
            //
            setValueField(objectform, 'isDraft', 'GENERATE');
            setValueform((currentValueform) => ({ ...currentValueform, ['isDraft']: { key: 'GENERATE', label: 'Generada' } }));
            //etObjectform(newobject);
            setForceSave(true);
      };

      const clickRectify = (object) => {
            service.rectify(object['id'], (data, error) => {
                  if (data && data.data) {
                        window.location.href = '/bill/edit/' + data.data['id'];
                  }
            });
      };

      const moreActions = (setValueField, objectform, valueform, setValueform, setObjectform) => {
            return objectform.isDraft == 'DRAFT' ? (
                  <Button variant="contained" color="secondary" style={{ marginLeft: '30px' }} onClick={(evt) => clickGenrate(setValueField, objectform, valueform, setValueform, setObjectform)}>
                        {' '}
                        Guardar y generar{' '}
                  </Button>
            ) : (
                  <Button variant="contained" color="secondary" style={{ marginLeft: '30px' }} onClick={(evt) => clickRectify(objectform)}>
                        {' '}
                        Generar rectificativa{' '}
                  </Button>
            );
      };

      useEffect(() => {
            configurationService.one(1, (data, error) => {
                  if (data && data.data) {
                        setConfiguration(data.data);
                        debugger;
                        if (!id) {
                              setForceRepait(+moment(new Date()).format('ss'));
                        }
                  }
            });
      }, []);

      return (
            <CrudForm
                  objectform={{ ...initElement }}
                  estructureform={estructureform}
                  titleEdit={'Editar factura'}
                  titleNew={'Nueva factura'}
                  urledit={'/bill/edit/'}
                  urlCancel={'/bill'}
                  service={service}
                  recoveryElement={recoveryElement}
                  valid={isValid}
                  haveMoreActions={true}
                  moreActions={moreActions}
                  forcesave={forcesave}
            />
      );
}

import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {holidaysService as service,clinicService} from "../../../services"
import {isAdmin,idClinic,clinicName} from '../../utils/Utils'

const initElement = {
     clinic:{id:idClinic(),name:clinicName()},
     endDate:"",
     startDate:""
  };

export default function FormHoliday() {
  const [objectform, setObjectform] = React.useState(initElement);


  const estructureform =  [
          {
           type:"autocomplete",
           name: "clinic.id",
           label: "Clinica",
           service:clinicService.combo,
           readonly:!isAdmin(),
           col: 4
          },
          {
           type:"input",
           name: "startDate",
           inputtype: "date",
           label: "Inicio",
           col: 4
          },
          {
           type:"input",
           name: "endDate",
           inputtype: "date",
           label: "Fin",
           col: 4
          },
    ];


  const recoveryElement = function(objectedit,element){
    objectedit['clinic']={id:element.clinic && element.clinic.id?element.clinic.id:null, name: element.clinic && element.clinic.name?element.clinic.name:null};
    objectedit['startDate']=element.startDate?element.startDate:"";
    objectedit['endDate']=element.endDate?element.endDate:"";

    return objectedit;
  }


  const isValid = function(object){
      if(!object['clinic'] || !object['clinic']['id']){
        toast.notify("Debe indicar la clinica", {position:'top', duration: 10000})
        return false;
      }
      if(!object['endDate'] || object['endDate'] == ""){
        toast.notify("Debe indicar el día de fin", {position:'top', duration: 10000})
        return false;
      }
      if(!object['startDate'] || object['startDate'] == ""){
        toast.notify("Debe indicar el día de inicio", {position:'top', duration: 10000})
        return false;
      }
      if(object['startDate'] > object['endDate']){
        toast.notify("La fecha de inicio debe ser anterior a la de fin", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar periodo de vacaciones"}
        titleNew = {"Nuevo periodo de vacaciones"}
        urledit={"/holiday/edit/"}
        urlCancel={"/holiday"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}

import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {insuranceService as service} from "../../../services"

const initFilter =  {
    name : null,
    email: null,
    city:null,
};

export default function Clinic() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
     {
       type:"input",
       name: "email",
       label: "Email",
       col: 4
      },
       {
         type:"input",
         name: "city",
         label: "Población",
         col: 4
        },
  ];



  return (
    <CrudTable
          service={service}
          titlePage={"Aseguradoras"}
          titleButton={"Nueva aseguradora"}
          urledit={"/insurance/edit/"}
          urlnew={"/insurance/new/"}
          filter={initFilter}
          fieldstable={["picture","nif","name","email","phone"]}
          headersTable={["#","NIF","Nombre","Email", "Tlf."]}
          estructureForm={createEstructureFilter}
      />
  );
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header from '../layout/Header';
import Menu from '../layout/Menu';
import Copyright from '../layout/Copyright';
import { useStyles, drawerWidth } from '../layout/styles';
import CustomPaginationActionsTable from '../table/Table';
import Filter from '../filter/Filter';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';

export default function CrudTable(props) {
      const classes = useStyles();
      const history = useHistory();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [filter, setFilter] = useState(props.filter ? props.filter : {});
      const [exportbtn, setExportbtn] = useState(props.exportbtn ? true : false);
      const fieldstable = props.fieldstable ? props.fieldstable : [];
      const headersTable = props.headersTable ? props.headersTable : [];

      const exportfields = props.exportfields ? props.exportfields : props.fieldstable;
      const exportlabels = props.exportlabels ? props.exportlabels : props.headersTable;

      const service = props.service ? props.service : null;
      const estructureForm = props.estructureForm ? props.estructureForm : null;
      const urledit = props.urledit ? props.urledit : '';
      const urlnew = props.urlnew ? props.urlnew : '';
      const titlePage = props.titlePage ? props.titlePage : '';
      const fieldId = props.fieldId ? props.fieldId : 'id';
      const titleButton = props.titleButton ? props.titleButton : 'Nuevo';
      const hiddenFilter = props.hiddenFilter ? props.hiddenFilter : false;
      const editable = props.editable != null ? props.editable : true;
      const servicescustom = props.servicescustom != null ? props.servicescustom : null;

      const [open, setOpen] = useState(false);
      const [rows, setRows] = useState([{}]);
      const [rowsTotal, setRowsTotal] = useState(0);

      const [{ exportexcel, exportword, exportpdf }, setState] = useState({
            exportexcel: 0,
            exportword: 0,
            exportpdf: 0,
      });

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const changePageTable = (page, size) => {
            search(page, size);
      };

      const cleanFilter = (filter) => {
            console.log(filter);
            setFilter(filter);
            search(0, rowsPerPage);
      };

      const searchFilter = (filter) => {
            console.log(filter);
            setFilter(filter);
            search(0, rowsPerPage);
      };

      const search = (page, size) => {
            setPage(page);
            setRowsPerPage(size);
            if (servicescustom) {
                  servicescustom(setRows, setRowsTotal);
            } else {
                  service.pageFilter(page, size, filter, (data, error) => {
                        if (data) {
                              console.log(data);
                              setRows(data.data);
                              setRowsTotal(data.total);
                        } else {
                              setRows([{}]);
                              setRowsTotal(0);
                        }
                  });
            }
      };

      useEffect(() => {
            search(0, 10);
      }, []);

      const deleteHandler = (id) => {
            service.removeLogic(id, (data, error) => {
                  if (data) {
                        console.log(data);
                        search(page, rowsPerPage);
                  }
            });
      };

      const eventSelect = (id) => {
            if (editable) {
                  if (props.gotoEdit) {
                        rows.forEach((element) => {
                              if (element[fieldId] == id) {
                                    props.gotoEdit(element);
                              }
                        });
                  } else {
                        history.push(urledit + id);
                  }
            }
      };

      const clickExportExcel = () => {
            setState((currentState) => ({ ...currentState, ['exportexcel']: currentState['exportexcel'] + 1 }));
      };

      const clickExportWord = () => {
            setState((currentState) => ({ ...currentState, ['exportword']: currentState['exportword'] + 1 }));
      };

      const clickExportPdf = () => {
            setState((currentState) => ({ ...currentState, ['exportpdf']: currentState['exportpdf'] + 1 }));
      };

      useEffect(() => {
            if (exportexcel > 0) {
                  setState((currentState) => ({ ...currentState, ['exportexcel']: 0 }));

                  var bodyrequest = {
                        filter: filter,
                        headers: exportlabels,
                        fields: exportfields,
                        header: false,
                        title: titlePage,
                  };

                  service.allFilterExcel(bodyrequest, (data, error) => {});
            }
      }, [exportexcel, filter, exportfields, exportlabels]);

      useEffect(() => {
            if (exportword > 0) {
                  setState((currentState) => ({ ...currentState, ['exportword']: 0 }));

                  var bodyrequest = {
                        filter: filter,
                        headers: exportlabels,
                        fields: exportfields,
                        header: false,
                        title: titlePage,
                  };

                  service.allFilterWord(bodyrequest, (data, error) => {});
            }
      }, [exportword, filter, exportfields, exportlabels]);

      useEffect(() => {
            if (exportpdf > 0) {
                  setState((currentState) => ({ ...currentState, ['exportpdf']: 0 }));

                  var bodyrequest = {
                        filter: filter,
                        headers: exportlabels,
                        fields: exportfields,
                        header: false,
                        title: titlePage,
                  };

                  service.allFilterPdf(bodyrequest, (data, error) => {});
            }
      }, [exportpdf, filter, exportfields, exportlabels]);

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title={titlePage} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              {!hiddenFilter && (
                                    <Grid container style={{ marginBottom: '50px' }}>
                                          <Filter
                                                cleanFilter={cleanFilter}
                                                searchFilter={searchFilter}
                                                filter={filter}
                                                titlebutton={titleButton}
                                                estructure={estructureForm}
                                                urlnew={urlnew}
                                                hiddenButton={props.hiddenButton ? props.hiddenButton : false}
                                                moreactions={props.moreactions ? props.moreactions : <></>}
                                          />
                                    </Grid>
                              )}
                              {hiddenFilter && (
                                    <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                          <Button component={Link} to={props.urlnew ? props.urlnew : '#'} variant="contained" color="secondary" style={{ marginLeft: '30px' }}>
                                                {' '}
                                                {titleButton}{' '}
                                          </Button>
                                    </Grid>
                              )}
                              {exportbtn && (
                                    <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                          <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px' }} onClick={clickExportExcel}>
                                                {' '}
                                                <FontAwesomeIcon icon={faFileExcel} />{' '}
                                          </Button>
                                          <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px', color: 'red' }} onClick={clickExportPdf}>
                                                {' '}
                                                <FontAwesomeIcon icon={faFilePdf} />{' '}
                                          </Button>
                                          <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px', color: 'blue' }} onClick={clickExportWord}>
                                                {' '}
                                                <FontAwesomeIcon icon={faFileWord} />{' '}
                                          </Button>
                                          {props.exportactions && props.exportactions()}
                                    </Grid>
                              )}
                              {!exportbtn && props.exportactions && (
                                    <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                                          {props.exportactions && props.exportactions(filter)}
                                    </Grid>
                              )}
                              <Grid container className={classes.bodycontainer}>
                                    <CustomPaginationActionsTable
                                          rows={rows}
                                          fields={fieldstable}
                                          fieldId={fieldId}
                                          rowsPerPage={10}
                                          rowsTotal={rowsTotal}
                                          changePage={changePageTable}
                                          headers={headersTable}
                                          page={page}
                                          deleteaction={props.deleteaction != null ? props.deleteaction : true}
                                          deleteactionHandler={deleteHandler}
                                          selectHandler={eventSelect}
                                          getDeleteButton={props.getDeleteButton ? props.getDeleteButton : null}
                                          editable={editable}
                                          moreactionstable={props.moreactionstable}
                                    />
                              </Grid>
                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                  </main>
            </div>
      );
}

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../crud/CrudForm';
import { appointmentService as service, userService, clinicService, insuranceService, patientService, treatmentService } from '../../../services';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';

const initElement = {
      clinic: { id: idClinic(), name: clinicName() },
      insurance: { id: null, name: null },
      patient: { id: null, name: null },
      fisio: { id: null, name: null },
      dateAppointment: null,
      hourAppointment: null,
      isPayament: false,
      isBill: false,
      notes: '',
      canceled: false,
      motiveCanceled: '',
      treatment: [],
};

export default function FormAppointment() {
      const [objectform, setObjectform] = React.useState({ ...initElement });
      let { id } = useParams();
      const [idRow, setIdrow] = useState(id);

      const estructureform = isAdmin()
            ? [
                    {
                          type: 'autocomplete',
                          name: 'patient.id',
                          label: 'Paciente',
                          service: patientService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'clinic.id',
                          label: 'Clinica',
                          service: clinicService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'insurance.id',
                          label: 'Aseguradora',
                          service: insuranceService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'fisio.id',
                          label: 'Fisio',
                          service: userService.combo,
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'dateAppointment',
                          label: 'Fecha',
                          inputtype: 'date',
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'hourAppointment',
                          label: 'Hora',
                          inputtype: 'time',
                          col: 4,
                    },
                    {
                          type: 'checkbox',
                          name: 'isBill',
                          label: 'Facturado',
                          col: 4,
                    },
                    {
                          type: 'checkbox',
                          name: 'isPayament',
                          label: 'Pagado',
                          col: 4,
                    },
                    {
                          type: 'textarea',
                          name: 'notes',
                          label: 'Notas',
                          col: 12,
                    },
                    {
                          type: 'textarea',
                          name: 'valoration',
                          label: 'Valoración',
                          col: 12,
                    },
                    {
                          type: 'checkbox',
                          name: 'notPresent',
                          label: 'No se presento',
                          col: 4,
                    },
                    {
                          type: 'checkbox',
                          name: 'canceled',
                          label: 'Cancelada',
                          col: 4,
                    },
                    {
                          type: 'textarea',
                          name: 'motiveCanceled',
                          label: 'Motivo de cancelacion',
                          col: 12,
                    },
                    {
                          type: 'autocomplete',
                          name: 'treatment',
                          multiple: true,
                          label: 'Tratamiento',
                          service: treatmentService.combo,
                          col: 12,
                    },
              ]
            : [
                    {
                          type: 'autocomplete',
                          name: 'patient.id',
                          label: 'Paciente',
                          service: patientService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'insurance.id',
                          label: 'Aseguradora',
                          service: insuranceService.combo,
                          col: 4,
                    },
                    {
                          type: 'autocomplete',
                          name: 'fisio.id',
                          label: 'Fisio',
                          service: userService.combo,
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'dateAppointment',
                          label: 'Fecha',
                          inputtype: 'date',
                          col: 4,
                    },
                    {
                          type: 'input',
                          name: 'hourAppointment',
                          label: 'Hora',
                          inputtype: 'time',
                          col: 4,
                    },
                    {
                          type: 'checkbox',
                          name: 'isBill',
                          label: 'Facturado',
                          col: 4,
                    },
                    {
                          type: 'checkbox',
                          name: 'isPayament',
                          label: 'Pagado',
                          col: 4,
                    },
                    {
                          type: 'textarea',
                          name: 'notes',
                          label: 'Notas',
                          col: 12,
                    },
                    {
                          type: 'textarea',
                          name: 'valoration',
                          label: 'Valoración',
                          col: 12,
                    },
                    {
                          type: 'checkbox',
                          name: 'notPresent',
                          label: 'No se presento',
                          col: 4,
                    },
                    {
                          type: 'checkbox',
                          name: 'canceled',
                          label: 'Cancelada',
                          col: 4,
                    },
                    {
                          type: 'textarea',
                          name: 'motiveCanceled',
                          label: 'Motivo de cancelacion',
                          col: 12,
                    },
                    {
                          type: 'autocomplete',
                          name: 'treatment',
                          multiple: true,
                          label: 'Tratamiento',
                          service: treatmentService.combo,
                          col: 12,
                    },
              ];

      const recoveryElement = function (objectedit, element) {
            objectedit['clinic'] = { id: element.clinic && element.clinic.id ? element.clinic.id : null, name: element.clinic && element.clinic.name ? element.clinic.name : null };
            objectedit['insurance'] = { id: element.insurance && element.insurance.id ? element.insurance.id : null, name: element.insurance && element.insurance.name ? element.insurance.name : null };
            objectedit['patient'] = { id: element.patient && element.patient.id ? element.patient.id : null, name: element.patient && element.patient.name ? element.patient.name : null };
            objectedit['fisio'] = { id: element.fisio && element.fisio.id ? element.fisio.id : null, name: element.fisio && element.fisio.name ? element.fisio.name : null };
            objectedit['dateAppointment'] = element.dateAppointment ? element.dateAppointment : '';
            objectedit['hourAppointment'] = element.hourAppointment ? element.hourAppointment : '';
            objectedit['isPayament'] = element.isPayament ? element.isPayament : false;
            objectedit['notes'] = element.notes ? element.notes : '';
            objectedit['canceled'] = element.canceled ? element.canceled : false;
            objectedit['motiveCanceled'] = element.motiveCanceled ? element.motiveCanceled : '';
            objectedit['treatment'] = element.treatment ? element.treatment : [];

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['patient'] || !object['patient']['id']) {
                  toast.notify('Debe indicar el paciente', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['clinic'] || !object['clinic']['id']) {
                  toast.notify('Debe indicar la clinica', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['fisio'] || !object['fisio']['id']) {
                  toast.notify('Debe indicar el fisio', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['dateAppointment']) {
                  toast.notify('Debe indicar la fecha', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['hourAppointment']) {
                  toast.notify('Debe indicar la hora', { position: 'top', duration: 10000 });
                  return false;
            }

            return true;
      };

      return (
            <CrudForm
                  objectform={{ ...initElement }}
                  estructureform={estructureform}
                  titleEdit={'Editar Cita'}
                  titleNew={'Nueva Cita'}
                  urledit={'/appointment/edit/'}
                  urlCancel={'/appointment'}
                  service={service}
                  recoveryElement={recoveryElement}
                  valid={isValid}
            />
      );
}

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import FormComplete from '../../crud/FormComplete';
import { useStyles, drawerWidth } from '../../layout/styles';
import { patientService as service, clinicService, insuranceService } from '../../../services';
import { isAdmin, idClinic, clinicName } from '../../utils/Utils';
import Document from './Document';
import AppointmentPatient from './AppointmentPatient';

const initElementPatient = {
      nif: '',
      email: '',
      phone: '',
      name: '',
      lastname: '',
      specialPrice: null,
      insurance: { id: null, name: '' },
      clinic: { id: idClinic(), name: clinicName() },
      picture: null,
      city: '',
      street: '',
      postalcode: '',
      job: '',
      cause: '',
      numpol: '',
      dateBirthday: null,
      age: null,
      initialValoration: null,
      dateInitValoration: null,
};

const initElementOtherData = {
      operations: '',
      diseases: '',
      medication: '',
      hobbies: '',
      pregnant: '',
      other: '',
};

const initElementTreatment = {
      treatmentPlan: '',
      evolution: '',
      finalValoration: '',
};

export default function FormPatient() {
      let { id } = useParams();
      const classes = useStyles();
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      const titleEdit = 'Editar Paciente';
      const titleNew = 'Nuevo Paciente';

      const [titleWindow, setTitleWindow] = useState(id && id > 0 ? titleEdit : titleNew);
      const [idRow, setIdrow] = useState(id);
      const [idSerie, setIdSerie] = useState(-1);
      const [cities, setCities] = useState([]);
      const [reload, setReload] = useState(false);
      const [tab, setTab] = useState(1);

      const [open, setOpen] = useState(false);

      const handleDrawerOpen = () => {
            setOpen(true);
      };
      const handleDrawerClose = () => {
            setOpen(false);
      };

      const estructureform = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Nombre',
                  col: 8,
            },
            /*{
                  type: 'input',
                  name: 'lastname',
                  label: 'Apellido',
                  col: 4,
            },*/
            {
                  type: 'input',
                  name: 'nif',
                  label: 'Nif',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'phone',
                  label: 'Teléfono',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'dateBirthday',
                  label: 'Fecha de Nacimiento',
                  inputtype: 'date',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'age',
                  label: 'Edad',
                  inputtype: 'number',
                  step: 1,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'email',
                  label: 'Email',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'clinic.id',
                  label: 'Clinica',
                  service: clinicService.combo,
                  readonly: !isAdmin(),
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'insurance.id',
                  label: 'Aseguradora',
                  service: insuranceService.combo,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'numpol',
                  label: 'Nº Afiliado',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'specialPrice',
                  label: 'Precio especial',
                  inputtype: 'number',
                  step: '0.01',
                  col: 4,
            },
            {
                  type: 'image',
                  name: 'picture',
                  label: 'Imagen',
                  col: 3,
            },
            {
                  type: 'title',
                  name: 'titlestreet',
                  label: 'Dirección',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'street',
                  label: 'Calle',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'postalcode',
                  label: 'C.P.',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'city',
                  label: 'Ciudad',
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlemore',
                  label: 'Mas informacion',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'job',
                  label: 'Trabajo',
                  col: 4,
            },
            {
                  type: 'textarea',
                  name: 'diagnostico',
                  label: 'Diagnostico',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'cause',
                  label: 'Causa',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'dateInitValoration',
                  label: 'Fecha V. Inicial',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'textarea',
                  name: 'initialValoration',
                  label: 'Valoracion Inicial',
                  col: 12,
            },
      ];

      const estructureformTreatment = [
            {
                  type: 'textarea',
                  name: 'treatmentPlan',
                  label: 'Plan de tratamiento',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'evolution',
                  label: 'Evolución',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'finalValoration',
                  label: 'Evaluación Final',
                  col: 12,
            },
      ];

      const estructureformOtherData = [
            {
                  type: 'textarea',
                  name: 'operations',
                  label: 'Operaciones/Implantes metálicos',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'diseases',
                  label: 'Enfermedades',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'medication',
                  label: 'Medicación',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'hobbies',
                  label: 'Aficiones',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'pregnant',
                  label: 'Embarazada',
                  col: 12,
            },
            {
                  type: 'textarea',
                  name: 'other',
                  label: 'Otros',
                  col: 12,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['email'] = element.email ? element.email : '';
            objectedit['nif'] = element.nif ? element.nif : '';
            objectedit['name'] = element.name ? element.name : '';
            objectedit['specialPrice'] = element.specialPrice ? element.specialPrice : null;
            objectedit['phone'] = element.phone ? element.phone : '';
            //objectedit['lastname'] = element.lastname ? element.lastname : '';
            objectedit['insurance'] = { code: element.insurance && element.insurance.id ? element.insurance.id : null, name: element.insurance && element.insurance.name ? element.insurance.name : null };
            objectedit['clinic'] = { id: element.clinic && element.clinic.id ? element.clinic.id : idClinic(), name: element.clinic && element.clinic.name ? element.clinic.name : clinicName() };
            objectedit['picture'] = element.picture ? element.picture : '';
            objectedit['city'] = element.city ? element.city : '';
            objectedit['street'] = element.street ? element.street : '';
            objectedit['postalcode'] = element.postalcode ? element.postalcode : '';
            objectedit['job'] = element.job ? element.job : '';
            objectedit['cause'] = element.cause ? element.cause : '';
            objectedit['numpol'] = element.numpol ? element.numpol : '';
            objectedit['dateBirthday'] = element.dateBirthday ? element.dateBirthday : '';
            objectedit['dateInitValoration'] = element.dateInitValoration ? element.dateInitValoration : '';
            objectedit['age'] = element.age ? element.age : null;
            objectedit['initialValoration'] = element.initialValoration ? element.initialValoration : '';
            objectedit['operations'] = element.operations ? element.operations : '';
            objectedit['diseases'] = element.diseases ? element.diseases : '';
            objectedit['medication'] = element.medication ? element.medication : '';
            objectedit['hobbies'] = element.hobbies ? element.hobbies : '';
            objectedit['pregnant'] = element.pregnant ? element.pregnant : '';
            objectedit['other'] = element.other ? element.other : '';
            objectedit['treatmentPlan'] = element.treatmentPlan ? element.treatmentPlan : '';
            objectedit['evolution'] = element.evolution ? element.evolution : '';
            objectedit['finalValoration'] = element.finalValoration ? element.finalValoration : '';

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['name']) {
                  toast.notify('Debe indicar el nombre', { position: 'top', duration: 10000 });
                  return false;
            }

            return true;
      };

      return (
            <div className={classes.root}>
                  <CssBaseline />
                  <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                  <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                              <Grid container spacing={3}>
                                    <Grid item sm={12} xs={12} style={{ textAlign: 'left', borderBottom: '1px solid #ccc', marginBottom: '2em' }}>
                                          <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={() => setTab(1)} className={tab == 1 ? classes.tabselected : classes.tabnotselected}>
                                                {' '}
                                                Datos del paciente{' '}
                                          </Button>
                                          {idRow > 0 && (
                                                <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={() => setTab(2)} className={tab == 2 ? classes.tabselected : classes.tabnotselected}>
                                                      {' '}
                                                      Otra información{' '}
                                                </Button>
                                          )}
                                          {idRow > 0 && (
                                                <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={() => setTab(3)} className={tab == 3 ? classes.tabselected : classes.tabnotselected}>
                                                      {' '}
                                                      Datos del tratamiento{' '}
                                                </Button>
                                          )}
                                          {idRow > 0 && (
                                                <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={() => setTab(4)} className={tab == 4 ? classes.tabselected : classes.tabnotselected}>
                                                      {' '}
                                                      Documentos{' '}
                                                </Button>
                                          )}
                                          {idRow > 0 && (
                                                <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={() => setTab(5)} className={tab == 5 ? classes.tabselected : classes.tabnotselected}>
                                                      {' '}
                                                      Citas{' '}
                                                </Button>
                                          )}
                                    </Grid>
                              </Grid>
                              <Grid container>
                                    {tab == 1 && (
                                          <FormComplete
                                                objectform={{ ...initElementPatient }}
                                                estructureform={estructureform}
                                                titleEdit={'Editar paciente'}
                                                titleNew={'Nuevo paciente'}
                                                urledit={'/patient/edit/'}
                                                urlCancel={'/patient'}
                                                service={service}
                                                recoveryElement={recoveryElement}
                                                aftersave={(data) => setIdrow(data.id)}
                                                valid={isValid}
                                          />
                                    )}
                                    {tab == 2 && idRow > 0 && (
                                          <FormComplete
                                                objectform={{ ...initElementOtherData }}
                                                estructureform={estructureformOtherData}
                                                titleEdit={'Editar paciente'}
                                                titleNew={'Nuevo paciente'}
                                                urledit={'/patient/edit/'}
                                                urlCancel={'/patient'}
                                                service={service}
                                                recoveryElement={recoveryElement}
                                                valid={isValid}
                                          />
                                    )}
                                    {tab == 3 && idRow > 0 && (
                                          <FormComplete
                                                objectform={{ ...initElementTreatment }}
                                                estructureform={estructureformTreatment}
                                                titleEdit={'Editar paciente'}
                                                titleNew={'Nuevo paciente'}
                                                urledit={'/patient/edit/'}
                                                urlCancel={'/patient'}
                                                service={service}
                                                recoveryElement={recoveryElement}
                                                valid={isValid}
                                          />
                                    )}
                                    {tab == 4 && idRow > 0 && <Document id={idRow} />}
                                    {tab == 5 && idRow > 0 && <AppointmentPatient id={idRow} />}
                              </Grid>
                              <Box pt={4}>
                                    <Copyright />
                              </Box>
                        </Container>
                  </main>
            </div>
      );
}

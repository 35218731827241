import BaseService from './_base.service';

const URL = "/admin/patient";

export const patientService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    searchDocuments,
    saveFileDocuments,
    deleteDocument
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}


function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}


function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}


function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}


function searchDocuments(id,callback) {
    return BaseService.get(URL+"/"+id+"/documents", callback)
}



function saveFileDocuments(id,document,callback) {
    let body = JSON.stringify(document)
    return BaseService.post(URL+"/"+id+"/documents",body, callback,true)
}

function deleteDocument(id,document,callback) {
    return BaseService.delete(URL+"/"+id+"/documents/"+document, callback)
}


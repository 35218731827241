import React, { useState, useEffect } from 'react';
import CrudPage from '../../crud/CrudPage';
import { billService as service, clinicService, patientService } from '../../../services';
import Filter from '../../filter/Filter';
import { Grid, Button, Hidden } from '@material-ui/core';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import { Print } from '@material-ui/icons';

const moment = require('moment');

const initFilter = {
      from: '',
      to: '',
      clinic: null,
      patient: null,
      billdate: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
};

const useStyles1 = makeStyles((theme) => ({
      header: {
            fontWeight: 'bold',
            paddingTop: '1em',
            paddingBottom: '1em',
            borderBottom: '1px solid #ddd',
            [theme.breakpoints.down('xs')]: {
                  display: 'none',
            },
      },
      body: {
            paddingTop: '1em',
            paddingBottom: '1em',
            borderBottom: '1px solid #ddd',
            [theme.breakpoints.down('xs')]: {
                  display: 'none',
            },
      },
}));

export default function GenerateBill() {
      const classes = useStyles1();
      const initRows: any[] = [];
      const [filter, setFilter] = useState({ ...initFilter });
      const [rows, setRows] = useState<any>(initRows);
      const [generate, setGenerate] = useState(false);
      const [total, setTotal] = useState<any>(0);

      const estructureForm = [
            {
                  type: 'input',
                  name: 'from',
                  label: 'Desde',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'to',
                  label: 'Hasta',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'clinic',
                  label: 'Clinica',
                  service: clinicService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'patient',
                  label: 'Paciente',
                  service: patientService.combo,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'billdate',
                  label: 'Fecha de la factura',
                  inputtype: 'date',
                  col: 4,
            },
      ];

      const searchFilter = (filter) => {
            if (filter['from'] == null || filter['from'] == '') {
                  toast.notify('Debe indicar la fecha desde', { position: 'top', duration: 10000 });
            } else if (filter['to'] == null || filter['to'] == '') {
                  toast.notify('Debe indicar la fecha hasta', { position: 'top', duration: 10000 });
            } else if (filter['billdate'] == null || filter['billdate'] == '') {
                  toast.notify('Debe indicar la fecha de la factura', { position: 'top', duration: 10000 });
            } else {
                  setFilter(filter);
                  setGenerate(false);
                  service.showPatient(
                        moment(Date.parse(filter['from'])).format('YYYYMMDD'),
                        moment(Date.parse(filter['to'])).format('YYYYMMDD'),
                        filter['clinic'] != null && filter['clinic'] != '' ? filter['clinic'] : -1,
                        filter['patient'] != null && filter['patient'] != '' ? filter['patient'] : -1,
                        moment(Date.parse(filter['billdate'])).format('YYYYMMDD'),
                        (data, error) => {
                              if (data && data.data) {
                                    setRows(data.data);
                                    var t = 0;
                                    for (var a = 0; a < data.data.length; a++) {
                                          t = t + data.data[a]['total'];
                                    }
                                    setTotal(t);
                              } else {
                                    setRows([]);
                                    setTotal(0);
                              }
                        }
                  );
            }
      };

      const generateFilter = () => {
            setGenerate(false);
            service.generatePatient(
                  moment(Date.parse(filter['from'])).format('YYYYMMDD'),
                  moment(Date.parse(filter['to'])).format('YYYYMMDD'),
                  filter['clinic'] != null && filter['clinic'] != '' ? filter['clinic'] : -1,
                  filter['patient'] != null && filter['patient'] != '' ? filter['patient'] : -1,
                  moment(Date.parse(filter['billdate'])).format('YYYYMMDD'),
                  (data, error) => {
                        if (data && data.data) {
                              setGenerate(true);
                              setRows(data.data);
                        } else {
                              toast.notify('Error al generar', { position: 'top', duration: 10000 });
                        }
                  }
            );
      };

      const printBill = (id, name) => {
            service.print(id, name, (data, error) => {});
      };

      return (
            <CrudPage service={service} titlePage={'Generador de facturas'}>
                  <Filter searchFilter={searchFilter} filter={filter} titlebutton={''} estructure={estructureForm} hiddenButton={true} moreactions={<></>} isHiddenCancel={true} expanded={true} />
                  {rows.length == 0 && (
                        <Grid container>
                              <Grid xs={12}>
                                    <p style={{ padding: '0.5em', textAlign: 'center', fontWeight: 'bold', color: 'darkgreen' }}> No se han encontrado facturas a generar</p>
                              </Grid>
                        </Grid>
                  )}
                  {rows.length > 0 && (
                        <Grid container style={{ fontSize: '2em' }}>
                              <Grid xs={6}>
                                    <p style={{ padding: '0.5em', textAlign: 'left', fontWeight: 'bold', color: 'darkgreen' }}> Nº Facturas {rows.length}</p>
                              </Grid>
                              <Grid xs={6} style={{ textAlign: 'center' }}>
                                    <p style={{ padding: '0.5em', textAlign: 'right', fontWeight: 'bold', color: 'darkgreen' }}> Total {total.toFixed(2)} €</p>
                              </Grid>
                        </Grid>
                  )}
                  {!generate && rows.length > 0 && (
                        <>
                              <Grid container>
                                    <Grid xs={12}>
                                          <p style={{ padding: '0.5em', textAlign: 'center', fontWeight: 'bold', color: 'darkgreen' }}> A continuación se muestran las facturar a generar</p>
                                    </Grid>
                                    <Grid xs={12} style={{ textAlign: 'center' }}>
                                          <Button variant="contained" color="primary" style={{ marginLeft: '30px' }} onClick={() => generateFilter()}>
                                                {' '}
                                                Generar facturas{' '}
                                          </Button>
                                    </Grid>
                              </Grid>
                              <Grid className={classes.header} container>
                                    <Grid sm={3}>Paciente</Grid>
                                    <Grid sm={3}>Aseguradora</Grid>
                                    <Grid sm={5}>Concepto</Grid>
                                    <Grid sm={1}>Total</Grid>
                              </Grid>
                              {rows.map((row) => {
                                    return (
                                          <Grid className={classes.body} container>
                                                <Grid sm={3}>{row.client != null && row.client.name != null ? row.client.name : ''}</Grid>
                                                <Grid sm={3}>{row.insurance != null && row.insurance.name != null ? row.insurance.name : ''}</Grid>
                                                <Grid sm={5}>
                                                      <p style={{ whiteSpace: 'pre-wrap' }}> {row.lines != null ? row.lines[0].name : ''}</p>
                                                </Grid>
                                                <Grid sm={1}>{row.total != null && row.client.name != null ? row.total.toFixed(2) : 0.0} €</Grid>
                                          </Grid>
                                    );
                              })}
                        </>
                  )}
                  {generate && rows.length > 0 && (
                        <>
                              <Grid container>
                                    <Grid xs={12}>
                                          <p style={{ padding: '0.5em', textAlign: 'center', fontWeight: 'bold', color: 'darkgreen' }}> A continuación se muestran las facturas GENERADAS</p>
                                    </Grid>
                              </Grid>
                              <Grid className={classes.header} container>
                                    <Grid sm={1}>Nº</Grid>
                                    <Grid sm={2}>Paciente</Grid>
                                    <Grid sm={3}>Aseguradora</Grid>
                                    <Grid sm={4}>Concepto</Grid>
                                    <Grid sm={1}>Total</Grid>
                                    <Grid sm={1}></Grid>
                              </Grid>
                              {rows.map((row) => {
                                    return (
                                          <Grid className={classes.body} container>
                                                <Grid sm={1}>{row.serieNumber != null ? row.serieNumber : ''}</Grid>
                                                <Grid sm={2}>{row.client != null && row.client.name != null ? row.client.name : ''}</Grid>
                                                <Grid sm={3}>{row.insurance != null && row.insurance.name != null ? row.insurance.name : ''}</Grid>
                                                <Grid sm={4}>
                                                      <p style={{ whiteSpace: 'pre-wrap' }}> {row.lines != null ? row.lines[0].name : ''}</p>
                                                </Grid>
                                                <Grid sm={1}>{row.total != null && row.client.name != null ? row.total.toFixed(2) : 0.0} €</Grid>
                                                <Grid sm={1}>
                                                      <Button variant="contained" color="primary" style={{ marginLeft: '30px' }} onClick={() => printBill(row.id, row.serieNumber + '.pdf')}>
                                                            {' '}
                                                            <Print />{' '}
                                                      </Button>
                                                </Grid>
                                          </Grid>
                                    );
                              })}
                        </>
                  )}
            </CrudPage>
      );
}
